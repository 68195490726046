<template>
  <div class="request-to-attach window">
    <ui-button color="error" @click="step = 1" v-if="step==0">{{ $t('submit-an-attachment-request') }}</ui-button>
    <request-first-step
        v-if="step==1"
        @back="step=0"
        @sendRequest="sendRequest($event)"
    />
    <request-second-step
        v-if="step==2"
        :description="description"
        :documentFile="documentFile"
        :documentSrc="documentSrc"
    />
    <request-third-step
        v-if="step==3"
        :isAttached="isAttached"
        :description="description"
        :documentFile="documentFile"
        :documentSrc="documentSrc"
        :denyComment="denyComment"
        @back="goBack"
        @nextStep="step=4"
    />
    <request-fourth-step
        v-if="step==4"
        :isAttached="isAttached"
        :descriptionClone="description"
        :documentFileClone="documentFile"
        :documentSrcClone="documentSrc"
        :denyComment="denyComment"
        @back="goBack"
        @sendRequestAgain="sendRequestAgain"
    />
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import RequestFirstStep from "@/components/template/work-requests/ViewRequests/requestToAttach/FirstStep.vue";
import RequestSecondStep from "@/components/template/work-requests/ViewRequests/requestToAttach/SecondStep.vue";
import RequestThirdStep from "@/components/template/work-requests/ViewRequests/requestToAttach/ThirdStep.vue";
import RequestFourthStep from "@/components/template/work-requests/ViewRequests/requestToAttach/FourthStep.vue";
import {mapActions} from "vuex";
export default {
  name: "RequestToAttach",
  components: {RequestFourthStep, RequestThirdStep, RequestSecondStep, RequestFirstStep, UiButton},

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      step: 0,
      description: '',
      denyComment: '',
      documentFile: [],
      documentSrc: [],
      isAttached: false
    }
  },

  methods: {
    ...mapActions(['orderRequest']),

    goBack () {
      this.step = 0
    },
    sendRequest (data) {
      this.description = data.description
      this.documentFile = data.documentFile
      this.documentSrc = data.documentSrc
      this.orderRequest({id: this.data.id, requestComment: this.description, requestFiles: this.documentFile,}).then(() => {
        this.step = 2
      })
    },

    sendRequestAgain (data) {
      this.description = data.description
      this.documentFile = data.documentFile
      this.documentSrc = data.documentSrc
      this.orderRequest({id: this.data.id, requestComment: this.description, requestFiles: this.documentFile,}).then(() => {
        this.step = 2
      })
    }
  },

  mounted() {
    if(this.data.myParticipationStatus === "Denied") {
      this.denyComment = this.data.myParticipation.denyComment
      this.description = this.data.myParticipation.requestComment
      this.documentFile = this.data.myParticipation.requestFiles
      this.documentSrc = this.data.myParticipation.requestFilesSrc
      this.isAttached = false
      this.step = 3
    }else if (this.data.myParticipationStatus === "Request") {
      this.denyComment = this.data.myParticipation.denyComment
      this.description = this.data.myParticipation.requestComment
      this.documentFile = this.data.myParticipation.requestFiles
      this.documentSrc = this.data.myParticipation.requestFilesSrc
      this.step = 2
    }else if(this.data.myParticipationStatus === "Approved") {
      this.denyComment = this.data.myParticipation.denyComment
      this.description = this.data.myParticipation.requestComment
      this.documentFile = this.data.myParticipation.requestFiles
      this.documentSrc = this.data.myParticipation.requestFilesSrc
      this.isAttached = true
      this.step = 3
    }
  }
}
</script>

<style lang="scss" scoped>
.window {

}
::v-deep .button {
  max-width: 340px;
}
</style>