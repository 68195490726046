<template>
  <div class="reject-window">
    <div class="reject-window__action" v-if="step===0">
      <ui-button @click="sendConfirm">{{ $t('transfer-to-work-1') }}</ui-button>
      <ui-button color="error" @click="step=1">{{ $t('reject') }}</ui-button>
    </div>
    <div class="reject-window__first" v-if="step===1">
      <div class="reject-window__first-header">
        <p>{{ $t('rejected-attachment') }}</p>
        <img
            src="@/assets/svg/arrows/up-black.svg"
            alt=""
            @click="visibleFirstWindow = !visibleFirstWindow"
            :class="['arrow', { rotate: visibleFirstWindow }]"
        />
      </div>
      <div class="reject-window__first-main" v-if="visibleFirstWindow">
        <ui-textarea
            v-model="description"
            height="80"
            radius="10"
            :label="$t('reason-for-rejection')"
            required-field
        />
        <div class="reject-window__first-main-action">
          <ui-button @click="sendRefuse" :disabled="!description">{{ $t('send') }}</ui-button>
          <ui-button color="white" class="cancel-button" @click="step=0">{{ $t('cancel-2') }}</ui-button>
        </div>
      </div>
    </div>
    <div class="reject-window__second" v-if="step===2">
      <div class="reject-window__second-header">
        <p><img src="@/assets/svg/close/red.svg" alt=""/>{{ $t('you-have-rejected-the-attachment') }}</p>
        <img
            src="@/assets/svg/arrows/up-black.svg"
            alt=""
            @click="visibleSecondWindow = !visibleSecondWindow"
            :class="['arrow', { rotate: visibleSecondWindow }]"
        />
      </div>
      <div class="reject-window__second-main" v-if="visibleSecondWindow">
        <p>{{ $t('reason-for-rejection') }}</p>
        <span>{{ description || data.myParticipation.refuseComment}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/ui/UiButton.vue";
import UiTextarea from "@/components/ui/UiTextarea.vue";
import {mapActions} from "vuex";

export default {
  name: "RejectWindow",
  components: {UiTextarea, UiButton},

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      visibleFirstWindow: true,
      visibleSecondWindow: false,
      description: '',
      step: 0
    }
  },

  methods: {
    ...mapActions(['orderConfirm', 'orderRefuse']),
    sendConfirm () {
      this.orderConfirm({id: this.data.id}).then(() => {
        this.$router.push('/work-requests')
      })
    },

    sendRefuse () {
      this.orderRefuse({id: this.data.id, comment: this.description}).then(() => {
        this.step = 2
      })
    }
  },

  mounted () {
    this.data.myParticipationStatus === 'Refused' ? this.step = 2 : ''
  }
}
</script>

<style lang="scss" scoped>
.reject-window {
  &__action {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__first {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px;
    border: 2px dashed #F5F5F5;
    border-radius: 10px;
    transition: .3s;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrow {
        cursor: pointer;
        transform: rotate(180deg);
        transition: 0.5s;

        @media (max-width: 992px) {
          top: 45px;
          right: 19px;
        }
      }

      .rotate {
        transform: rotate(0deg) !important;
      }

      p {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      gap: 30px;
      border-top: 1px solid #F5F5F5;
      padding-top: 20px;

      &-action {
        display: flex;
        align-items: center;
      }
    }
  }

  &__second {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px;
    border: 2px dashed #F5F5F5;
    border-radius: 10px;
    transition: .3s;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrow {
        cursor: pointer;
        transform: rotate(180deg);
        transition: 0.5s;

        @media (max-width: 992px) {
          top: 45px;
          right: 19px;
        }
      }

      .rotate {
        transform: rotate(0deg) !important;
      }

      p {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-top: 1px solid #F5F5F5;
      padding-top: 20px;

      p {
        color: #9A9A9A;
        font-size: 14px;
        font-weight: 400;
      }

      span {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
.cancel-button {
  box-shadow: none !important;
  background-color: transparent !important;

  &:hover {
    background-color: #FFFFFF !important;
    color: #1B1A1F !important;
  }
}
</style>
