<template>
  <div class="fourth-step">
    <div class="fourth-step__title">
      <p>{{ $t('adjust-request') }}</p>
      <img
          src="@/assets/svg/arrows/up-black.svg"
          alt=""
          @click="visibleWindow = !visibleWindow"
          :class="['arrow', { rotate: visibleWindow }]"
      />
    </div>
    <div class="fourth-step__main" v-if="visibleWindow">
      <div class="fourth-step__main-description">
        <p>{{ $t('reason-for-rejection') }}</p>
        <span>{{ denyComment }}</span>
      </div>
      <div class="fourth-step__main-form">
        <ui-textarea
            height="80"
            radius="10"
            v-model="description"
            :label="$t('purpose-of-attachment')"
            required-field
        />
        <div class="fourth-step__main-form-upload upload">
          <p class="upload__text">
            {{ $t('documents') }}
          </p>
          <div class="upload__wrap">
            <label>
              <input type="file" ref="file" @input="uploadDocument" accept="image/*" >
              <span class="upload__wrap-btn">
            <img src="@/assets/svg/icon-plus-white.svg" alt="" />
          </span>
            </label>
            <a
                v-for="(item, key) in documentSrc"
                :href="item.url"
                target="_blank"
                style="display: flex"
                :key="key"
                class="upload__wrap-file"
            >
              <img
                  src="@/assets/svg/icon-docs-grey.svg"
                  alt=""
              />
              <div class="upload__wrap-file-close" @click="deleteFile($event, key)">
                <img
                    src="@/assets/svg/close/white.svg"
                    alt=""
                />
              </div>
              <div class="upload__wrap-file-info">
                <p>{{ item.name || item.originalname }}</p>
                <span>{{ getFullDate(new Date())}}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="fourth-step__main-action" v-if="visibleWindow">
        <ui-button :disabled="!description" @click="$emit('sendRequestAgain', {description: description, documentFile: documentFile, documentSrc: documentSrc})">{{ $t('send') }}</ui-button>
        <ui-button color="white" class="cancel-button" @click="$emit('back')">{{ $t('cancel-2') }}</ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import UiTextarea from "@/components/ui/UiTextarea.vue";
import {getFullDate} from "@/utils/general";
import {mapActions} from "vuex";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "RequestFourthStep",
  components: {UiButton, UiTextarea},

  props: {
    descriptionClone: {
      type: String,
      default: ''
    },
    denyComment: {
      type: String,
      default: ''
    },
    documentFileClone: {
      type: Array,
      default: () => []
    },
    documentSrcClone: {
      type: Array,
      default: () => []
    },
    isAttached: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      visibleWindow: true,
      description: '',
      documentFile: [],
      documentSrc: []
    }
  },

  methods: {
    getFullDate,
    ...mapActions(['reqUpload']),
    uploadDocument({ target }) {
      this.reqUpload({
        file: target.files[0],
        type: "document",
      }).then((res) => {
        this.documentFile.push(res.data.filename)
        this.documentSrc.push(res.data)
        this.$refs.file.value = null
      })
    },

    deleteFile(e, key) {
      e.preventDefault();
      this.documentFile = this.documentFile.filter((elem, idx) => idx !== key);
      this.documentSrc = this.documentSrc.filter((elem, idx) => idx !== key);
    },
  },

  mounted () {
    this.description = this.descriptionClone
    this.documentFile = this.documentFileClone
    this.documentSrc = this.documentSrcClone
  }
}
</script>

<style lang="scss" scoped>
.fourth-step {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border: 2px dashed #F5F5F5;
  border-radius: 10px;
  max-width: 570px;

  @media (max-width: 768px) {
    padding: 20px 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .arrow {
      cursor: pointer;
      transform: rotate(180deg);
      transition: 0.5s;

      @media (max-width: 992px) {
        top: 45px;
        right: 19px;
      }
    }

    .rotate {
      transform: rotate(0deg) !important;
    }

    p {
      color: #1B1A1F;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    border-top: 1px solid #F5F5F5;

    &-description {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #F5F5F5;

      p {
        color: #E21F1F;
        font-size: 14px;
        font-weight: 400;
      }

      span {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .upload {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__text {
          font-weight: 400;
          font-size: 12px;
          line-height: 11px;
          color: #343432;
          margin-left: 30px;
        }

        &__wrap {
          display: flex;
          flex-wrap: nowrap;
          gap: 10px;
          overflow-x: auto;
          right: 1rem;
          width: 100%;
          height: 100%;
          padding: 10px 0;

          &::-webkit-scrollbar {
            width: 20px;
            height: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background: #9A9A9A;
            border-radius: 10px;
          }

          &-btn {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E21F1F;
            color: #fff;
            border-radius: 50%;
            cursor: pointer;
          }

          input {
            display: none;
          }


          &-file {
            position: relative;
            flex-direction: column;
            align-items: center;
            max-width: 116px;
            min-width: 114px;
            gap: 10px;

            &-info {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;

              p {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #1B1A1F;
                word-break: break-all;
              }

              span {
                font-weight: 400;
                font-size: 10px;
                line-height: 112.7%;
                color: #BFBDBD;
                width: 58px;
                text-align: center;
              }
            }

            &-close {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              top: 0;
              right: 24px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background: #E21F1F;

              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }

    &-action {
      display: flex;
      align-items: center;

      @media (max-width: 500px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
.cancel-button {
  box-shadow: none !important;
  background-color: transparent !important;

  &:hover {
    background-color: #FFFFFF !important;
    color: #1B1A1F !important;
  }
}
</style>