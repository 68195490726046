<template>
  <div class="third-step">
    <div class="third-step__header">
      <div class="third-step__header-left">
        <img src="@/assets/svg/close/red.svg" alt="" v-if="!isAttached" />
        <img src="@/assets/svg/icon-check-green-double.svg" alt="" v-else/>
        <p>{{ getTitle }}</p>
      </div>
      <img
          src="@/assets/svg/arrows/up-black.svg"
          alt=""
          @click="visibleWindow = !visibleWindow"
          :class="['arrow', { rotate: visibleWindow }]"
      />
    </div>
    <div class="third-step__main" v-if="visibleWindow">
      <div class="third-step__main-description" v-if="!isAttached">
        <p>{{ $t('reason-for-rejection') }}</p>
        <span>{{ denyComment || $t('empty') }}</span>
      </div>
      <div class="third-step__main-text">
        <p>{{ $t('purpose-of-attachment') }}</p>
        <span>{{ description || $t('empty') }}</span>
      </div>
      <p>{{ $t('documents') }}</p>
      <div class="third-step__main-documents">
        <a
            v-for="(item, key) in documentSrc"
            :key="key"
            :href="item.fileSrc"
            target="_blank"
            style="display: flex; align-items: center; gap: 10px;"
        >
          <img

              src="@/assets/svg/icon-docs-grey.svg"
              alt=""
          />
          <div class="third-step__main-documents-info">
            <p>{{ item.name || item.originalname }}</p>
            <span>{{ getFullDate(item.createdAt || new Date())}}</span>
          </div>
        </a>
        <span v-if="!documentSrc.length">{{ $t('empty') }}</span>
      </div>
      <div class="third-step__main-action" v-if="!isAttached">
        <ui-button @click="$emit('nextStep')">{{ $t('adjust-request') }}</ui-button>
        <ui-button color="white" class="cancel-button" @click="$emit('back')">{{ $t('cancel-request') }}</ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getFullDate} from "../../../../../utils/general";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "RequestThirdStep",
  components: {UiButton},
  methods: {getFullDate},

  props: {
    description: {
      type: String,
      default: ''
    },
    documentFile: {
      type: Array,
      default: () => []
    },
    documentSrc: {
      type: Array,
      default: () => []
    },
    denyComment: {
      type: String,
      default: ''
    },
    isAttached: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      visibleWindow: false
    }
  },

  computed: {
    getTitle () {
      return this.isAttached ? this.$t('your-request-has-been-approved') : this.$t('your-application-has-been-rejected')
    }
  }
}
</script>

<style lang="scss" scoped>
.third-step {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border: 2px dashed #F5F5F5;
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 20px 12px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &-left {
      display: flex;
      align-items: center;
      gap: 20px;

      p {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    border-top: 1px solid #F5F5F5;

    p {
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 400;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 10px;

      span {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-documents {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      a {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 5px;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #1B1A1F;
          word-break: break-all;

          @media (max-width: 768px) {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: #1B1A1F;
          }
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #BFBDBD;

          @media (max-width: 768px) {
            font-weight: 400;
            font-size: 8px;
            line-height: 112.7%;
            color: #BFBDBD;
            width: 42px;
            text-align: center;
          }
        }
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #F5F5F5;

      p {
        color: #E21F1F;
        font-size: 14px;
        font-weight: 400;
      }

      span {
        color: #1B1A1F;
        font-size: 14px;
        font-weight: 400;
      }
    }

    &-action {
      display: flex;
      align-items: center;
    }
  }
}
.arrow {
  cursor: pointer;
  transform: rotate(180deg);
  transition: 0.5s;

  @media (max-width: 992px) {
    top: 45px;
    right: 19px;
  }
}

.rotate {
  transform: rotate(0deg) !important;
}

a {
  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 114px;
    width: 100%;
  }
}

.cancel-button {
  box-shadow: none !important;
  background-color: transparent !important;

  &:hover {
    background-color: #FFFFFF !important;
    color: #1B1A1F !important;
  }
}
</style>